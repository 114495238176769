






















































































































































import { Global } from '@/store/globalz';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const allPartners = ['czg', 'iogs', 'poki', 'svg', 'isg', 'minic'];
const partners = ['czg', 'iogs'];

@Component({
  components: {},
})
export default class PartnerBar extends Vue {
  public portal: string = (this.$route.query.p as string) || '';

  public get partnerLabel() {
    if (Global.partner || this.portal) {
      return 'Partners:';
    }
    return 'More IO Games';
  }

  public mounted() {
    if (!Global.partner) {
      Global.partner = this.portal;
    }
    this.portal = (this.$route.query.p as string) || Global.partner;
    // if (!allPartners.includes(this.portal)) {
    //   this.portal = random.choose(partners);
    // }
  }
}
