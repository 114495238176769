



















































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NewDecoComponent from '../NewDecoComponent.vue';
import NewFishComponent from '../NewFishComponent.vue';
import NewSkinComponent from '../NewSkinComponent.vue';
import ProductButtonComponent from '@/components/shop/ProductButtonComponent.vue';
import { Global } from '@/store/globalz';
import FishSkillIconComponent from '@/components/ui/mainMenu/tools/fishSelector/FishSkillIconComponent.vue';
import DecoContestPoster from '@/components/ui/decoContest/DecoContestPoster.vue';
import globalx from '@/store/modules/globalx';

@Component({
	components: {
		NewDecoComponent,
		NewFishComponent,
		NewSkinComponent,
		ProductButtonComponent,
		FishSkillIconComponent,
		DecoContestPoster,
	},
})
export default class NewsPost extends Vue {
	public get time() {
		return globalx.time;
	}
	public get decoStage() {
		return globalx.autoDecoContestStage;
	}
	// public onMechguin() {
	// 	gamex.setGameStage('BossList');
	// }
	// public get isXmas() {
	// 	return isQuestTime(TimeLimitedQuestCode.Xmas2022);
	// }
	// public get isXmasPenguin() {
	// 	return isQuestTime(TimeLimitedQuestCode.XmasPenguin);
	// }
	public onCnyPurchase() {
		Global.cnyShopModal.show();
	}
	public playTutorial() {
		Global.$root.$emit('playTutorial');
	}
}
